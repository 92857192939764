<template>
    <div class="container">

        <div class="row">
            <div class="col-sm-8">
            <h3>Noch nicht Freigegeben</h3>
            </div>
            <div class="col-sm-4" style="text-align: right">
                <button class="btn btn-secondary" @click="confirmAll">Alle Freigeben</button>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
              <div class="c-grid-block">
            <table class="table table-hover" v-if="statusLoaded">
                <thead class="thead-dark2">
                <tr>
                    <th scope="col">Titel</th>
                    <th scope="col">Interpret</th>
                    <th scope="col">Album</th>
                    <th scope="col">Hinzugefügt von</th>
                    <th scope="col">
                        <div class="text-right">
                            Votes
                        </div>
                    </th>
                    <th scope="col">
                        <div class="text-right">
                            Aktionen

                        </div>
                    </th>
                </tr>

                </thead>
                <tbody>
                <tr v-if="unconfirmed.length === 0">
                    <td colspan="100%" align="center">
                        <span class="empty">Keine Tracks vorhanden</span>
                    </td>
                </tr>
                <tr v-for="trackEntry in unconfirmed">
                    <td scope="row">
                        <strong>{{ trackEntry.track.name }}</strong>&nbsp;
                    </td>
                    <td>{{ trackEntry.track.album.artist.name }}</td>
                    <td>{{ trackEntry.track.album.name }}</td>
                    <td>
                        <span v-if="trackEntry.client_id">{{ getClientName(trackEntry.client_id) }}</span>
                        <span v-else>Host</span>
                    </td>
                    <td>
                        <div class="text-right">
                            {{ trackEntry.votes }}
                        </div>
                    </td>
                    <td>
                        <div class="text-right">
                            <a class="btn btn-secondary btn-sm" @click="confirmTrack(trackEntry)">
                                <i class="fas fa-check"></i>
                            </a>
                            <a class="btn btn-secondary btn-sm" @click="banTrack(trackEntry)">
                                <i class="fas fa-ban"></i>
                            </a>
                            <a class="btn btn-secondary btn-sm" @click="removeTrack(trackEntry)">
                                <i class="fas fa-trash"></i>
                            </a>
                        </div>
                    </td>
                </tr>
                </tbody>

            </table>
              </div>
            </div>
            <div class="col-sm-12">
            <h3>Gebannte Tracks</h3>
              <div class="c-grid-block">
            <table class="table table-hover" v-if="statusLoaded">
                <thead class="thead-dark2">
                <tr>
                    <th scope="col">Titel</th>
                    <th scope="col">Interpret</th>
                    <th scope="col">Album</th>
                    <th scope="col">Hinzugefügt von</th>
                    <th scope="col">
                        <div class="text-right">
                            Votes
                        </div>
                    </th>
                    <th scope="col">
                        <div class="text-right">
                            Aktionen

                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="banned.length === 0">
                    <td colspan="100%" align="center">
                        <span class="empty">Keine Tracks vorhanden</span>
                    </td>
                </tr>
                <tr v-for="trackEntry in banned">
                    <td scope="row">
                        <strong>{{ trackEntry.track.name }}</strong>&nbsp;
                    </td>
                    <td>{{ trackEntry.track.album.artist.name }}</td>
                    <td>{{ trackEntry.track.album.name }}</td>
                    <td>
                        <span v-if="trackEntry.client_id">{{ getClientName(trackEntry.client_id) }}</span>
                        <span v-else>Host</span>
                    </td>
                    <td>
                        <div class="text-right">
                            {{ trackEntry.votes }}
                        </div>
                    </td>
                    <td>
                        <div class="text-right">
                            <a class="btn btn-secondary btn-sm" @click="unBanTrack(trackEntry)">
                                <i class="fas fa-check"></i>
                            </a>
                            <a class="btn btn-secondary btn-sm" @click="removeTrack(trackEntry)">
                                <i class="fas fa-trash"></i>
                            </a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        name: 'Home',
        components: {},
        mounted: async function () {
            this.loadData()
        },
        destroyed () {
            window.clearTimeout(this.loadTimeout)
        },
        watch: {},
        methods: {
            async confirmAll() {
                window.clearInterval(this.loadTimeout)

                await axios.post('/host/track', {
                    method: 'confirmAll',
                })

                this.loadData()
            },
            async removeTrack (entry) {
                window.clearInterval(this.loadTimeout)

                await axios.post('/host/track', {
                    method: 'remove',
                    entry_id: entry.entry_id
                })

                this.loadData()
            },
            async confirmTrack (entry) {
                window.clearInterval(this.loadTimeout)

                await axios.post('/host/track', {
                    method: 'confirm',
                    entry_id: entry.entry_id
                })

                this.loadData()
            },
            async banTrack (entry) {
                window.clearInterval(this.loadTimeout)

                await axios.post('/host/track', {
                    method: 'ban',
                    entry_id: entry.entry_id
                })

                this.loadData()

            },
            async unBanTrack (entry) {
                window.clearInterval(this.loadTimeout)

                await axios.post('/host/track', {
                    method: 'unban',
                    entry_id: entry.entry_id
                })

                this.loadData()

            },
            async loadData () {
                const response = await axios.get('/host/waitlist')

                this.unconfirmed = response.data.unconfirmed
                this.banned = response.data.banned

                this.loadTimeout = window.setTimeout(this.loadData, 5000)
            }
        },
        computed: {},
        data: function () {
            return {
                unconfirmed: [],
                banned: [],
                loadTimeout: null
            }
        }
    }
</script>
<style lang="stylus" scoped>

.table-hover tbody tr:hover td {
  background-color: rgba(69, 153, 55, 0.2);
}

.thead-dark2{
  background-color: rgba(69, 153, 55, 0.2);
}
.c-grid-block {
  background-color: rgba(126, 186, 118,0.1);
  color: #000000;
  /* box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.1), 0 0.3rem 0.1rem 0 rgba(0, 0, 0, 0.05);
  */
}

</style>